import React from 'react';
import { has } from 'lodash';
import styles from './image-white-box.module.scss';
import Image from 'gatsby-image';
import SiteLink from '../site-link';

const FeaturedImageWithWhiteBoxComingsoon = ({ data }) => {
  return (
    <div className={styles.imageWhiteBoxContainer}>
      {!!data.image && has(data.image, 'fluid') && <Image fluid={data.image.fluid}/>}
      <div className={styles.imageWhiteBoxContent}>
        <h3 className={styles.imageWhiteBoxTitle}>{data.title}</h3>
        <div className={styles.imageWhiteBoxText}
         dangerouslySetInnerHTML={{ __html: data.text }}
        />
        {data.link && <SiteLink href={data.link.uri}>{data.link.title}</SiteLink>}
      </div>
    </div>
  );
};

export default FeaturedImageWithWhiteBoxComingsoon;
