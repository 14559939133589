import React from 'react';
import { get, cloneDeep } from 'lodash';
import styles from './comingsoon-container.module.scss';
import ImageHero from '../common/image-hero/image-hero.js';
import FeaturedImageWithWhiteBoxComingsoon from '../common/image-white-box/image-white-box-comingsoon.js';

import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import GatewayComponentComingsoon from '../common/gateway-component/gateway-component-comingsoon.js';

const ComingsoonContainer = ({ data }) => {
  console.log(data);
  return (
    <div className={styles.sustainabilityContainerMain}>
      <div className={styles.sustainabilityContainerHero}>
        <ImageHero data={data.hero} />
      </div>
      <div className={styles.sustainabilityContainerBody}>
        <FeaturedImageWithWhiteBoxComingsoon data={data.section1} />

        <div className={styles.sustainabilityContainerCards}>
          <GatewayComponentComingsoon data={data.section2} />
          <GatewayComponentComingsoon data={data.section3} />
        </div>
      </div>
    </div>
  );
};

export default ComingsoonContainer;
