import React from 'react';
import styles from './gateway-component.module.scss';
import Img from 'gatsby-image';
import SiteLink from '../site-link';
import { has } from 'lodash';


const GatewayComponentComingsoon = ({ data }) => {

  return (
    <div className={styles.gatewayComponentMain}>
      {!!data.image && has(data.image,'fluid') && <Img className={styles.gatewayComponentImage} fluid={data.image.fluid} />}
      <div className={styles.gatewayComponentContent}>
        <h3 className={styles.gatewayComponentTitle}>{data.title}</h3>
        <div className={styles.gatewayComponentText} 
        dangerouslySetInnerHTML={{ __html: data.text}}
        />
        {data.link && <SiteLink href={data.link.uri}>{data.link.title}</SiteLink>}
      </div>
    </div>
  );
};

export default GatewayComponentComingsoon;

