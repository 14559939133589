// Libs.
import React from 'react';
import { graphql } from 'gatsby'
// Deps.
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import { formatFieldsValue } from '../libs/formatFromGraph';
import ComingsoonContainer from '../components/coming-soon/comingsoon-container';

const ComingSoonPage = ({ data }) => {
  const pageData = formatFieldsValue(data);

  return (
    <Layout>
      <SEO title="Coming Soon" />
      <ComingsoonContainer  data={pageData}/>
    </Layout>
  );
};

export default ComingSoonPage;

export const query = graphql`
query My {
hero: configPagesComingSoon {
    body: field_coming_soon_hero_descripti {
      value
    }
    title: field_coming_soon_hero_title {
      value
    }
    relationships {
      image: field_coming_soon_hero_image {
        localFile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1170) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  section1: configPagesComingSoon {
    text: field_section_1_description {
      value
    }
    title: field_section_1_title {
      value
    }
    relationships {
      image: field_section_1_image {
        localFile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1170) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    link: field_section_1_link {
      title
      uri
    }
  }
  section2: configPagesComingSoon {
    text: field_section_2_description {
      value
    }
    title: field_section_2_title {
      value
    }
    relationships {
      image: field_section_2_image {
        localFile {
          childImageSharp {
            fluid(maxHeight: 555) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    link: field_section_2_ {
      title
      uri
    }
  }
  section3: configPagesComingSoon {
    text: field_section_3_description {
      value
    }
    title: field_section_3_title {
      value
    }
    relationships {
      image: field_section_3_image {
        localFile {
          childImageSharp {
            fluid(maxHeight: 555) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    link: field_section_3_link {
      title
      uri
    }
  }
}
`;